export enum PlanType {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export enum PackageType {
  STARTER = 'STARTER',
  GROWTH = 'GROWTH',
  SCALE = 'SCALE',
  CORPORATE = 'CORPORATE',
}

export interface Feature {
  name: string;
  description: string;
  icon: string;
  is_featured?: boolean;
  package: PackageType;
}

export interface Package {
  name: PackageType;
  description: string;
}

export interface Plan {
  package: PackageType;
  planDetail: string;
  price: number;
  bestValue?: boolean;
  type: PlanType;
  discount?: number;
}

export const plans: Plan[] = [
  {
    package: PackageType.STARTER,
    planDetail: 'Ideal for small teams setting up essential operations',
    price: 8,
    type: PlanType.MONTHLY,
  },
  {
    package: PackageType.STARTER,
    planDetail: 'Ideal for small teams setting up essential operations',
    price: 8,
    discount: 20,
    type: PlanType.QUARTERLY,
  },
  {
    package: PackageType.STARTER,
    planDetail: 'Ideal for small teams setting up essential operations',
    price: 8,
    discount: 30,
    bestValue: true,
    type: PlanType.YEARLY,
  },
  {
    package: PackageType.GROWTH,
    planDetail: 'Optimized for mid-sized companies with growing teams',
    price: 18,
    type: PlanType.MONTHLY,
  },
  {
    package: PackageType.GROWTH,
    planDetail: 'Optimized for mid-sized companies with growing teams',
    price: 18,
    discount: 20,
    type: PlanType.QUARTERLY,
  },
  {
    package: PackageType.GROWTH,
    planDetail: 'Optimized for mid-sized companies with growing teams',
    price: 18,
    discount: 30,
    bestValue: true,
    type: PlanType.YEARLY,
  },
  {
    package: PackageType.SCALE,
    planDetail:
      'Best for companies preparing to scale their teams and activities',
    price: 35,
    type: PlanType.MONTHLY,
  },
  {
    package: PackageType.SCALE,
    planDetail:
      'Best for companies preparing to scale their teams and activities',
    price: 35,
    discount: 20,
    type: PlanType.QUARTERLY,
  },
  {
    package: PackageType.SCALE,
    planDetail:
      'Best for companies preparing to scale their teams and activities',
    price: 35,
    discount: 30,
    bestValue: true,
    type: PlanType.YEARLY,
  },
  {
    package: PackageType.CORPORATE,
    planDetail:
      'For companies with complex, multi-department needs, we can customize a pricing plan that scales to your business needs.',
    price: 100,
    type: PlanType.MONTHLY,
  },
  {
    package: PackageType.CORPORATE,
    planDetail:
      'For companies with complex, multi-department needs, we can customize a pricing plan that scales to your business needs.',
    price: 100,
    discount: 20,
    type: PlanType.QUARTERLY,
  },
  {
    package: PackageType.CORPORATE,
    planDetail:
      'For companies with complex, multi-department needs, we can customize a pricing plan that scales to your business needs.',
    price: 100,
    discount: 30,
    bestValue: true,
    type: PlanType.YEARLY,
  },
];

export const packages: Package[] = [
  {
    name: PackageType.STARTER,
    description: 'Ideal for small teams setting up essential operations',
  },
  {
    name: PackageType.GROWTH,
    description: 'Ideal for small teams setting up essential operations',
  },
  {
    name: PackageType.SCALE,
    description: 'Ideal for small teams setting up essential operations',
  },
  {
    name: PackageType.CORPORATE,
    description: 'Ideal for small teams setting up essential operations',
  },
];

export const features: Feature[] = [
  {
    name: 'Accounting',
    description:
      'Manage your finances effortlessly with real-time tracking of income, expenses, and financial reports.',
    icon: 'star',
    package: PackageType.STARTER,
  },
  {
    name: 'Human Resource',
    description:
      'Streamline employee management with tools for recruitment, attendance, and performance tracking.',
    icon: 'star',
    package: PackageType.STARTER,
  },
  {
    name: 'Payroll',
    description:
      'Automate payroll processes, ensuring accurate and timely payments to your workforce.',
    icon: 'star',
    package: PackageType.STARTER,
  },

  {
    name: 'Everything in STARTER',
    description: 'Best feature to manage your business operations smoothly.',
    icon: 'star',
    is_featured: true,
    package: PackageType.GROWTH,
  },
  {
    name: 'Selling',
    description:
      'Easily manage all your sales processes from order management and Point-of-Sale (POS) to invoicing.',
    icon: 'star',
    package: PackageType.GROWTH,
  },
  {
    name: 'Customer Relationship Manager',
    description:
      'Build strong customer relationships with tools for managing interactions, sales, and support.',
    icon: 'star',
    package: PackageType.GROWTH,
  },
  {
    name: 'Buying',
    description:
      'Manage procurement and supplier relationships with streamlined purchasing processes.',
    icon: 'star',
    package: PackageType.SCALE,
  },

  {
    name: 'Everything in GROWTH',
    description: 'Best feature to manage your business operations smoothly.',
    icon: 'star',
    is_featured: true,
    package: PackageType.SCALE,
  },

  {
    name: 'Stock Management',
    description:
      'Maintain optimal inventory levels with real-time stock tracking and management.',
    icon: 'star',
    package: PackageType.SCALE,
  },
  {
    name: 'Manufacturing',
    description:
      'Oversee production with tools for managing workflows, resources, and output.',
    icon: 'star',
    package: PackageType.CORPORATE,
  },
  {
    name: 'Projects Management',
    description:
      'Plan, execute, and monitor projects efficiently with task management and timelines.',
    icon: 'star',
    package: PackageType.GROWTH,
  },
  {
    name: 'Everything in SCALE',
    description: 'Best feature to manage your business operations smoothly.',
    icon: 'star',
    is_featured: true,
    package: PackageType.CORPORATE,
  },
  {
    name: 'Customer Support',
    description:
      'Provide top-notch customer service with integrated support ticketing and management.',
    icon: 'star',
    package: PackageType.STARTER,
  },
  {
    name: 'Fixed Assets Management',
    description:
      'Keep track of your company’s assets, from acquisition to depreciation, in one place.',
    icon: 'star',
    package: PackageType.STARTER,
  },
  {
    name: 'Team Chat',
    description:
      'Enhance team communication with secure, instant messaging and collaboration tools.',
    icon: 'star',
    package: PackageType.STARTER,
  },
];
